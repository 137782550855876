import React, { useEffect, useState, useRef }  from 'react';
import { Link } from 'react-router-dom';

    const Wo7ms = ({ floorPrice, collectionStats, loadingLatestMints,loadingCollectionStats, latestMints, setActiveSection }) => {
    const [isAtTop, setIsAtTop] = useState(true);
    const wizardRef = useRef(null);

    const scrollToWizard = () => {
        if (wizardRef.current) {
            wizardRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex flex-col p-4 border-2 border-lime-500 overflow-hidden text-center">
            <p className="text-lg md:text-3xl text-red-700" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
                    🌶️ Mint <span style={{ fontFamily: 'Inter, sans-serif' }}>3</span> or more WO7MS to participate in{' '}
                        <Link 
                            to="https://discord.gg/mx5z8aTHrH" 
                            className="animate-[pulse_2s_infinite] text-red-700 cursor-pointer hover:text-red-600" 
                            title="Join discord for more details"
                        >
                            THE SPICE EVENT{' '}
                        </Link>
                        which is running until <span style={{ fontFamily: 'Inter, sans-serif' }}>800</span> WO7MS are minted 🌶️
                    </p>
         <div className="text-white p-4 rounded-lg w-full text-center mb-4 text-lime-500" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
                <div className="flex flex-col md:flex-row justify-around text-lime-500">
                        {loadingCollectionStats ? (
                            <p className="text-3xl md:text-4xl text-white">Loading...</p>
                        ) : (
                            <>
                                <div>
                        <p className="text-3xl md:text-4xl cursor-pointer" onClick={scrollToWizard}>
                            Floor Price: 
                            <span className="text-3xl md:text-4xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}>
                                {floorPrice || 'None Listed'}
                            </span> {floorPrice ? 'ATOM' : ''}
                        </p>
                        <p className="text-3xl md:text-4xl cursor-pointer" onClick={scrollToWizard}>
                            Mint Price: 
                            <span className="text-3xl md:text-4xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}>
                                1
                            </span> {floorPrice ? 'ATOM' : ''}
                        </p>
                        <p className="text-xl md:text-xl text-white cursor-pointer animate-[pulse_5s_infinite]"
                        onClick={scrollToWizard}>
                            Click to go ask ATOM price to the Wizard
                        </p>
                    </div>
                    <div>
                        <p className="text-3xl md:text-4xl">
                            Holders: 
                            <span className="text-3xl md:text-4xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}>
                                {collectionStats ? collectionStats.owners : 'N/A'}
                            </span>
                        </p>
                    </div>
                    <div>
                        <p className="text-3xl md:text-4xl">
                            Minted so Far: 
                            <span className="text-3xl md:text-4xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}>
                                {collectionStats ? `${collectionStats.supply} / 1000` : 'N/A'}
                            </span>
                        </p>
                    </div>
                            </>
                        )}
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-center w-fit mt-8 mb-12">
                <img 
                    className="h-auto cursor-pointer max-h-min self-center md:self-start mt-1" 
                    onClick={() => window.open('https://asteroidprotocol.io/app/collection/REALWO7MS', '_blank')}
                    title="Explore Collection on Asteroid Protocol" 
                    src="./collection.gif" 
                    alt="Collection animation"
                />
                <div className="pl-2 text-center flex flex-col items-center text-lime-500" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
                
                    <h1 className="text-3xl md:text-5xl font-bold mb-4">
                        <span className="text-white">1000</span> WO7MS INSCRIPTIONS
                    </h1>
                    <div className="flex flex-col md:flex-row justify-center items-center mb-6">
                        <h1 className="text-3xl md:text-5xl md:pr-5 font-bold text-lime-500">
                            on the CosmosHub <span className="text-lime-500">on</span>
                        </h1>
                        <div className="flex items-center">
                            <img 
                                src="./logoAsteroid.png" 
                                alt="Asteroid Protocol logo" 
                                className="h-8 md:h-11" 
                                title="Learn more about the protocol" 
                                onClick={() => window.open('https://docs.asteroidprotocol.io/', '_blank')}
                            />
                            <h1 
                                className="text-3xl md:text-5xl md:pl-0 font-bold text-white pl-1" 
                                title="Learn more about the protocol" 
                                onClick={() => window.open('https://docs.asteroidprotocol.io/', '_blank')}
                            >
                                steroid
                            </h1>
                        </div>
                    </div>
                    <h1 
                        className="animate-[pulse_1s_infinite] text-3xl md:text-5xl font-bold text-white cursor-pointer hover:text-lime-500" 
                        onClick={() => window.open('https://asteroidprotocol.io/app/launchpad/REALWO7MS', '_blank')}
                        title="Mint 1 WO7MS now"
                    >
                        ➡️ MINT NOW ⬅️
                    </h1>
                    <div className="flex border border-solid-1 border-gray-700 pl-2 pr-2 mt-2 mb-6 cursor-pointer"
            onClick={() => window.open('https://go.skip.build?dest_chain=cosmoshub-4&dest_asset=uatom', '_blank')}
            title="Swap from any chain to ATOM" >
                    <p className="text-xl mb-2 text-white mt-2 pr-2 opacity-50" 
                    onClick={() => window.open('https://go.skip.build?dest_chain=cosmoshub-4&dest_asset=uatom', '_blank')}
                    >Need 1 ATOM ? Use {' '}</p>
                    <img
                    className="max-w-16 item-center pb-2 opacity-80" 
                    src="./SkipLogo.svg" 
                    alt="SkipLogo"
                    
                    />
                    </div>
                    
                    <p className="text-lg md:text-xl text-white ">
                        All WO7MS minted outside of the Free Mint stages will be raffled for {' '}
                        <Link 
                            to="/mint-raffle-prizes" 
                            className="animate-[pulse_2s_infinite] text-white cursor-pointer hover:text-lime-600" 
                            title="Learn more about the Big Raffle"
                        >
                            BIG PRIZES
                        </Link>
                    </p>
                    <p className="text-lg md:text-xl text-white mb-2">
                        Many NFT prizes will be added as the mint progresses.
                    </p>
                    <p className="text-lg md:text-xl mb-2">7 Elements: Fire, Water, Earth, Air, Ice, Electric, Neon</p>
                    <p className="text-lg md:text-xl mb-2">WO7MS are a combination of elements from the ancient "wormic" Runes and each have different rarities even if they are all unique.</p>
                    <p className="text-lg md:text-xl mb-2 text-white">10 1/1s are hidden in the collection.</p>
                    <p className="text-lg md:text-xl text-lime-500 mb-2">
                        <span className="text-lg md:text-xl mb-2 text-white">30 WO7MS are associated with </span>
                        <Link to="/mint-bounties" className="animate-[pulse_2s_infinite] text-white cursor-pointer hover:text-lime-600" title="Learn more about the Bounties">BOUNTIES</Link>
                        {' '} (30 inscriptions from some OG collections) that will be dropped to their holders after the collection is minted out.
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-center" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            <h3 className="text-3xl font-bold mb-4 text-lime-500 place-self-center">Latest Mints</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
            {loadingLatestMints ? (
    <p className="text-lg">Loading...</p>
) : (
    Array.isArray(latestMints) && latestMints.length > 0 ? (
        latestMints.slice(0, 4).map(inscription => (
            <div key={inscription.metadata.metadata.name} className="relative border hover:scale-[1.02] text-lime-600 text-xl border-lime-800 rounded-lg p-4 bg-lime-900 shadow-md cursor-pointer">
                <img
                    src={inscription.content_path}
                    title='Click to see more details on Asteroid'
                    className="w-full h-auto rounded-md cursor-pointer"
                    alt={inscription.metadata.metadata.name}
                    onClick={() => window.open(`https://asteroidprotocol.io/app/inscription/${inscription.transaction.hash}`, '_blank')}
                />
                                        <h4 className="font-bold text-2xl text-white pt-2" style={{ fontFamily: 'Inter, sans-serif' }}>{inscription.metadata.metadata.name}</h4>
                                        <p className="text-xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}><strong> Rarity: {inscription.rarity ? `${inscription.rarity.rarity_rank} / ${collectionStats.supply}` : 'Calculating ...'}</strong></p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[0].trait_type} :{' '}{inscription.metadata.metadata.attributes[0].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[1].trait_type} :{' '}{inscription.metadata.metadata.attributes[1].value}</p>        
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[2].trait_type} :{' '}{inscription.metadata.metadata.attributes[2].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[3].trait_type} :{' '}{inscription.metadata.metadata.attributes[3].value} </p>
            </div>
        ))
    ) : (
        <p className="text-lg text-white">Issue fetching Latest Mints.</p>
    )
)}
</div>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center mt-6 mb-6">
                <img 
                    src="./madlearnmore.gif" 
                    alt="Scientist Learn more" 
                    className="cursor-pointer max-w-64 md:max-w-80 hover:scale-[1.05] mb-4 md:mr-16" 
                    title="Learn more about Asteroid Protocol" 
                    onClick={() => window.open('https://docs.asteroidprotocol.io/asteroid-protocol-docs/general-overview', '_blank')}
                />
                <div ref={wizardRef} className="relative w-1/4 md:w-1/6 lg:w-1/8 max-w-48 md-max-w-64 flex flex-col text-center items-center justify-center pt-12">
                    <p className="absolute top-0 left-1/2 transform -translate-x-1/2 text-xl text-white w-56 pt-20" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>Click on the Wizard and type E to see ATOM price (try Q, W, R)</p>
                    <iframe 
                        sandbox="allow-scripts" 
                        src="./wizard.svg" 
                        title="Pixel Wizard" 
                        style={{ border: 'none', width: '512px', height: '512px' }} 
                        className="md:pl-4 "
                    ></iframe>
                </div>
            </div>

    
            {/* Back to Top Button */}
            {!isAtTop && (
                <h1 
                className="fixed bottom-8 md:bottom-10 bg-black right-10 text-2xl text-white rounded-full p-3 shadow-lg hover:bg-lime-900 transition 
                 rounded-full border-2 border-solid border-lime-600 cursor-pointer"
                onClick={scrollToTop}
                title="Back to Top"
            >
                ↑
            </h1>
            )}
        </div>
    );
};

export default Wo7ms;
