import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const MintRafflePrizes = ({ collectionStats, loadingCollectionStats }) => {
    const rulesRef = useRef(null);
    const [isAtTop, setIsAtTop] = useState(true);

    const scrollToRules = () => {
        if (rulesRef.current) {
            rulesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex flex-col items-center text-center justify-center min-h-screen p-6 border-2 border-lime-500" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            <h1 className="text-5xl font-bold mb-4 text-lime-500">The Big Raffle</h1>
            {/* <div className="text-2xl text-white  border-2 border-solid border-white pl-2 pr-2">
                <p style={{ fontFamily: 'Inter, sans-serif' }}>
                   {!loadingCollectionStats && collectionStats ? `${collectionStats.supply - 157} entries so far` : 'Loading...'}
                </p>
                
            </div> */}
            <p className="text-xl mb-6 text-white cursor-pointer hover:text-lime-600" onClick={scrollToRules}>
                    Go to rules
                </p>
            <p className="text-lg md:text-2xl text-lime-500">
                Each minted WO7MS is an entry into the raffle for one of the collected prizes. 
            </p>
            <p className="text-lg md:text-2xl mb-2 text-lime-500 mb-6">
                Raffle prizes are stackable with {' '}
                <Link to="/mint-bounties" className="animate-[pulse_2s_infinite] text-white cursor-pointer hover:text-lime-600" title="Learn more about the Bounties">MINT BOUNTIES</Link> ...
            </p>
            <p className="animate-[pulse_1s_infinite] text-3xl hover:text-lime-600 text-white cursor-pointer" title="Go mint one WO7MS" onClick={() => window.open('https://asteroidprotocol.io/app/launchpad/REALWO7MS', '_blank')}>
                ➡️ ENTER ⬅️
            </p> 
            <p className="animate-[pulse_1s_infinite] text-3xl hover:text-lime-600 text-white cursor-pointer" title="Go mint one WO7MS" onClick={() => window.open('https://asteroidprotocol.io/app/launchpad/REALWO7MS', '_blank')}>
                ONE WO7MS = ONE ENTRY = 1 ATOM
            </p>
            <div className="flex border border-solid-1 border-gray-700 pl-2 pr-2 mt-2 mb-6 cursor-pointer"
            onClick={() => window.open('https://go.skip.build?dest_chain=cosmoshub-4&dest_asset=uatom', '_blank')}
            title="Swap from any chain to ATOM" >
                <p className="text-xl mb-2 text-white mt-2 pr-2 opacity-50" title="Swap from any chain to ATOM">Need 1 ATOM ? Use {' '}</p>
                <img className="max-w-16 item-center pb-2 opacity-80" src="./SkipLogo.svg" alt="SkipLogo" />
            </div>
                    
            <h3 className="text-3xl font-bold mb-4 text-lime-600">Collected PRIZES :</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center text-center space-x-4">
    {/* Item 0 */}
    <div className="flex flex-col place-items-center">
        <p title="ONCHAIN OMIE #1540 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars1j5fhf04q6sycz72mz5uzrwvv2e05jy3npsdzppxyl2eww0x5hy4s0wuftp/1540', '_blank')} 
           className="text-2xl ml-1 mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}1.{' '}</span>
            ONCHAIN OMIE<span style={{ fontFamily: 'Inter, sans-serif' }}> #1540</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeighnzdhflbpqxso3p4tnxpubxfjtaav3mnz57cgadk4h72kekmrky/1540.png" 
            alt="Onchain OMie" 
            title="Onchain OMie #1540 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/stars1j5fhf04q6sycz72mz5uzrwvv2e05jy3npsdzppxyl2eww0x5hy4s0wuftp/1540', '_blank')}
        />
    </div>

    {/* Item 1 */}
    <div className="flex flex-col place-items-center">
        <p title="Mad Scientists #1009 details on Backbonelabs" 
           onClick={() => window.open('https://app.backbonelabs.io/necropolis/collections/osmo16pwjh09s662a0j2ssmzauyvkvagjwd9kpwc0mtamlwr8dtznlhfqcweap6/7875', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}2.{' '}</span>
            MAD SCIENTIST<span style={{ fontFamily: 'Inter, sans-serif' }}> #1009</span> - OSMOSIS
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="./madscientist.jpg" 
            alt="mad waiting" 
            title="Mad Scientists #1009 details on Backbonelabs"
            onClick={() => window.open('https://app.backbonelabs.io/necropolis/collections/osmo16pwjh09s662a0j2ssmzauyvkvagjwd9kpwc0mtamlwr8dtznlhfqcweap6/7875', '_blank')}
        />
    </div>

    {/* Item 2 */}
    <div className="flex flex-col place-items-center">
        <p title="Roncorin Wilnir details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars18d7ver7mmjdt06mz6x0pz09862060kupju75kpka5j0r7huearcsq0gyg0/376', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}3.{' '}</span>
            PIXEL WIZARD<span style={{ fontFamily: 'Inter, sans-serif' }}>   #376</span> - STARGAZE
        </p>
        <div className="relative w-80 h-80 mb-4">
            <iframe 
                sandbox="allow-scripts" 
                src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeiet7wzhih3zwcmdi2kojzpkrhjdrp7otaineans5zgg6e26yuj4qu/376.svg" 
                title="Pixel Wizard" 
                style={{ border: 'none', width: '100%', height: '100%', display: 'block' }} 
                className="absolute inset-0"
            ></iframe>
            <div style={{ paddingTop: '100%' }} /> {/* This maintains the aspect ratio */}
        </div>
    </div>

    {/* Item 3 */}
    <div className="flex flex-col place-items-center">
        <p title="Ammelia #1580 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/ammelia/1580', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}4.{' '}</span>
            AMMELIA <span style={{ fontFamily: 'Inter, sans-serif' }}> #1580</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/Qmc5LA2qtmPvc8TB86VahHRFLnvqzADxZUinUbMeyyb4Rw/1580.png" 
            alt="Ammelia" 
            title="Ammelia #1580 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/ammelia/1580', '_blank')}
        />
    </div>

    {/* Item 4 */}
    <div className="flex flex-col place-items-center">
        <p title="Geckies #2511 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars166kqwcu8789xh7nk07fcrdzek54205u8gzas684lnas2kzalksqsg5xhqf/2511', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}5.{' '}</span>
            GECKIES <span style={{ fontFamily: 'Inter, sans-serif' }}> #2511</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeidkcrey4jiwj3ypxedbz2b7tsdyjtb7zcfr6smhevhkjjcsq2tigq/2511.png" 
            alt="Fomo Foxes" 
            title="Geckies #2511 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/stars166kqwcu8789xh7nk07fcrdzek54205u8gzas684lnas2kzalksqsg5xhqf/2511', '_blank')}
        />
    </div>

    {/* Item 5 */}
    <div className="flex flex-col place-items-center">
        <p className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer" title="PandaWarrior #2825 details on Talis Injective">
            <span className="text-4xl mb-2 text-white">{' '}6.{' '}</span>
            PANDAWARRIOR<span style={{ fontFamily: 'Inter, sans-serif' }}> #2825</span> - INJECTIVE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="./PandaWarrior2825.gif" 
            alt="PandaWarrior" 
            title="PandaWarrior #2825 details on Talis Injective"
            onClick={() => window.open('https://injective.talis.art/nft/6701549aa55c4e6ee2f6b482?from=collection', '_blank')}
        />
    </div>

    {/* Item 6 */}
    <div className="flex flex-col place-items-center">
        <p className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer" title="Drama Queens #1157 details on Stargaze">
            <span className="text-4xl mb-2 text-white">{' '}7.{' '}</span>
            DRAMA QUEENS<span style={{ fontFamily: 'Inter, sans-serif' }}> #1157</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeibhs2db2hthmlnwfvbuduvorybvazltxdmir5w4zoidhzfrbmyvom/1157.png" 
            alt="Drama Queen" 
            title="Drama Queens #1157 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/dramaqueens/1157', '_blank')}
        />
    </div>

    {/* Item 7 */}
    <div className="flex flex-col place-items-center">
        <p className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer" title="PandaWarrior #2825 details on Talis Injective">
            <span className="text-4xl mb-2 text-white">{' '}8.{' '}</span>
            STARGAZE MASCOT<span style={{ fontFamily: 'Inter, sans-serif' }}> #2330</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeihoq6v4mqtjjptsiulq5dtdc4qw2yxyylol4f37ujfkosihge2uva/2330.png" 
            alt="Stargaze Mascot" 
            title="Stargaze Mascot #2330 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/sgmascotastro/2330', '_blank')}
        />
    </div>

    <div className="flex flex-col place-items-center">
        <p title="FoMo Foxes #527 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars176tumdcryjhp4dz7znkjev2r60jryc4mayfc2r8vn350jprag2zqqggpqa/527', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}9.{' '}</span>
            FOMO FOXES <span style={{ fontFamily: 'Inter, sans-serif' }}> #527</span> - STARGAZE
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeie5gavd4cavvveznzgos74znpwsj2tza2r7a67hig64wsg7hgn6wa/527.png" 
            alt="Fomo Foxes" 
            title="FoMo Foxes #527 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/stars176tumdcryjhp4dz7znkjev2r60jryc4mayfc2r8vn350jprag2zqqggpqa/527', '_blank')}
        />
    </div>

    {/* Item ? */}
    <div className="flex flex-col place-items-center mt-2">
        <p className="text-2xl mb-2 text-lime-600" title="More Prizes to be added soon ...">
        <span className="text-white">More Prizes Soon </span>(Bad Kid ?)<span className="text-white"> - ?</span>
        </p>
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="./questionMarkPrize.png" 
            alt="?" 
            title="More Prizes to be added soon ..."
        />
    </div>
</div>

            {/* <p className="text-2xl mb-2 text-lime-500 mb-6">
                ... A <span className="text-2xl mb-2 text-white">BAD KID</span> and <span className="text-2xl mb-2 text-white">more prizes</span> to be added soon ...
            </p> */}
            
            <div ref={rulesRef} className="p-6 rounded-lg text-center text-lime-500 mt-4" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
                <h2 className="text-3xl font-bold text-white mb-2">Rules :</h2>
                <ul className="text-lg md:text-2xl mb-4 list-disc list-inside text-white">
                    <li>For fairness to every participant, the winning WO7MS can't be a WO7MS gotten for FREE with FREE MINT TICKETS. It must have been minted during WL or PUBLIC.</li>
                    <li>A Bad Kid will be purchased with mint proceeds as soon as there are enough participants. <span className="text-lime-500">More prizes will be added as the mint progresses.</span></li>
                    <li>When all the WO7MS are minted, some WO7MS will be randomly picked (the 157 WO7MS gotten for free excluded) to win the prizes.</li>
                </ul>
            </div>
            <video 
                className="w-full md:w-3/4 lg:w-1/2 mb-4 rounded-lg shadow-lg" 
                controls
                title="WO7MS Raffle Video"
            >
                <source src="./badmad.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="flex justify-center w-full pl-16 pb-6 mt-6">
                <img 
                    src="./madlearnmore.gif" 
                    alt="Scientist Learn more" 
                    className="cursor-pointer max-w-64 md:max-w-80 hover:scale-[1.05] hover:ml-1 pb-3" 
                    title="Learn more about Asteroid Protocol" 
                    onClick={() => window.open('https://docs.asteroidprotocol.io/asteroid-protocol-docs/general-overview', '_blank')}
                />
            </div>
            {/* Back to Top Button */}
            {!isAtTop && (
                <h1 
                className="fixed bottom-2 md:bottom-10 right-10  bg-black text-2xl text-white rounded-full p-3 shadow-lg hover:bg-lime-900 transition 
                 rounded-full border-2 border-solid border-lime-600 cursor-pointer"
                onClick={scrollToTop}
                title="Back to Top"
            >
                ↑
            </h1>
            )}
            
        </div>
    );
};

export default MintRafflePrizes;
